.layout {
    flex-direction: column;
    display: flex;
    min-height: 100%;
}

.layout header {
    color: #fff;
    background-color: #00154b;
    font-family: 'IBMPlexSans';
}

.layout .content {
    flex: 1;
}

.info {
    background-color: #eef0f2;
    padding: 50px;
}

.info h3 {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 30px;
    color: #01174a;
    line-height: 35px;
    letter-spacing: 0px;
}

.info h4 {
    color: #01174a;
    font-size: 16px;
}

.info .image svg {
    font-size: 311px;
}

.resetPassword {
    background-color: #fff;
    padding: 50px;
}

.resetPassword .title {
    font-family: 'IBMPlexSans-SemiBold';
    color: #01174a;
    font-size: 35px;
    margin-top: 50px;
}

.resetPassword .description {
    margin-bottom: 50px;
}

.resetPassword input {
    border-color: #eef0f2;
}

.resetPassword .buttons {
    margin-top: 42px;
    margin-bottom: 8px;
}

.resetPassword .buttons button {
    opacity: 0.5;
}

.resetPassword .login {
    padding-left: 0;
    color: #2c78f2;
}
