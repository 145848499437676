.header {
    display: flex;
    flex-direction: row;
}

.header a {
    color: #fff;
}

.header .logo {
    padding-right: 24px;
}

.header .logo a {
    display: inline-block;
    font-weight: bold;
    height: 42px;
    font-size: 18px;
    color: #fff;
}

.header .menu {
    flex: 1;
}

.header .settings {
    margin-left: auto;
}

.header .settings .user,
.header .settings .user:hover {
    color: rgba(255, 255, 255, 0.65);
    padding-right: 0;
}

.header .settings .icon {
    color: #fff;
    font-size: 20px;
    vertical-align: middle;
}

.header .settings .type {
    color: #fff;
}

.submenu {
    margin-top: 13px !important;
    padding: 6px 0 !important;
}

.submenu li:hover {
    background-color: transparent;
}

.submenu div,
.submenu li,
.submenu li a,
.submenu li span {
    color: rgba(255, 255, 255, 0.65) !important;
}

.submenu li span {
    margin-right: 10px;
}

.submenu > li:hover,
.submenu > li:hover > span,
.submenu li ul li:hover a,
.submenu li ul li:hover span,
.submenu li.active,
.submenu li.active a,
.submenu li.active span {
    color: #fff !important;
}

.submenu .divider,
.submenu .divider:hover {
    background-color: rgba(255, 255, 255, 0.25) !important;
}
