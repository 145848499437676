.files {
    padding-left: 28px;
}

.files h4 {
    font-weight: 400;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
