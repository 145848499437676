@font-face {
    font-family: 'IBMPlexSans';
    src: local('IBMPlexSans-Regular'), url('./resources/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexSans-SemiBold';
    src: local('IBMPlexSans-SemiBold'), url('./resources/fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
}

body {
    margin: 0;
    font-family: 'IBMPlexSans', 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
    font-weight: 600;
}

a {
    color: #000;
}

.ant-table .ant-table-thead .ant-table-cell {
    font-weight: 600;
}

textarea.ant-input.ant-input-disabled {
    background-color: #fff;
}

.ant-input-search.search {
    border-bottom: 1px solid #d9d9d9;
}

.ant-input-search.search .ant-input-group-addon {
    background-color: transparent;
    padding-right: 6px;
    border: 0;
}

.ant-input-search.search .ant-input-group-addon .ant-input-search-button {
    display: none;
}

.ant-input-search.search .ant-input-group-addon svg {
    color: #0555e6;
    font-size: 18px;
    margin-top: 4px;
}

.ant-input-search.search .ant-input {
    font-family: 'IBMPlexSans';
}

.ant-input-search.search .ant-input::placeholder {
    color: #8c8c8c;
    opacity: 1;
}

.ant-input-search.search .ant-input::-ms-input-placeholder {
    color: #8c8c8c;
}

.ant-radio-group .ant-radio-wrapper span {
    font-weight: 400;
}

.ant-pagination .ant-pagination-item a,
.ant-pagination .ant-pagination-item-link {
    color: #8c8c8c;
}

.ant-pagination .ant-pagination-item.ant-pagination-item-active a {
    color: #8c8c8c;
    font-weight: 400;
}

.ant-input-textarea textarea {
    border: 0;
    border-radius: 0 ; 
    font-family: 'IBMPlexSans';
}

.ant-input-textarea.ant-input-textarea-show-count {
    font-size: 12px;
}

.ant-input[disabled] {
    cursor: default !important;
}

.ant-progress .ant-progress-text {
    font-family: 'IBMPlexSans-SemiBold';
}

.ant-avatar {
    font-weight: 600;
}

.ant-list-item-meta-title {
    font-weight: 600;
}

.ant-list-item-meta-description {
    color: #000 !important ;
}

.ant-steps-navigation .ant-steps-item {
    margin-right: 14px;
}

.ant-steps-navigation .ant-steps-item .ant-steps-item-icon {
    display: none;
}

.ant-steps-navigation .ant-steps-item-content {
    text-align: center;
}

.ant-steps-navigation .ant-steps-item-content .ant-steps-item-title {
    font-size: 20px !important;
    font-family: 'IBMPlexSans-SemiBold';
    color: #8c8c8c !important;
    text-transform: uppercase !important;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title {
    color: #043aaa !important;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active .ant-steps-item-content .ant-steps-item-title {
    color: #125ae2 !important;
}

.ant-steps-navigation .ant-steps-item-content .ant-steps-item-description {
    font-size: 13px !important;
    font-family: 'IBMPlexSans-SemiBold';
    color: #8c8c8c !important;
    max-width: none !important;
}

.ant-steps-navigation .ant-steps-item::before {
    left: 0 !important;
    width: 100% !important;
    background-color: #fafafa !important;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-finish::before {
    background-color: #01154b !important;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
    background-color: #125ae2 !important;
}

.ant-steps-navigation .ant-steps-item::after {
    display: none !important;
}

.ant-carousel .slick-dots li button {
    background-color: #fff !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #0555e6 !important;
}

.ant-card .ant-card-head {
    border-bottom: 0 none !important;
}

.ant-card .ant-card-head .ant-card-head-title {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 18px;
    padding-top: 36px;
    padding-bottom: 0;
}

.ant-statistic .ant-statistic-content {
    color: #000;
    font-size: 18px;
}

.ant-btn.ant-btn-ghost {
    border-color:  #d9d9d9;
}

.ant-btn.ant-btn-ghost:hover {
    color: #0555e6;
    border-color:  #0555e6;
}

.ant-modal-root .ant-modal .ant-modal-header {
    padding: 12px 24px;
    color: #000;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 0 0 0 0;
    margin: -20px -24px 24px -24px;
}

.ant-modal-root .ant-modal .ant-modal-title {
    font-family: 'IBMPlexSans-SemiBold';
    margin: 0;
    color: #01174a;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    word-wrap: break-word;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
    font-weight: 600;
}

.ant-modal-root .ant-modal .ant-modal-footer {
    margin: 48px -16px 0 -16px;
    padding: 16px 16px 0 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 0 0;
}

.ant-modal-root .ant-modal .ant-modal-body .ant-form-item input,
.ant-modal-root .ant-modal .ant-modal-body .ant-form-item textarea,
.ant-modal-root .ant-modal .ant-modal-body .ant-form-item .ql-container.ql-snow,
.ant-modal-root .ant-modal .ant-modal-body .ant-form-item .ql-toolbar.ql-snow,
.ant-modal-root .ant-modal .ant-modal-body .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px #d9d9d9 solid !important;
}

.ant-modal-root .ant-modal .ant-modal-body .ant-form-item .ant-picker-input input,
.ant-modal-root .ant-modal .ant-modal-body .ant-form-item .ant-select-selection-search  input{
    border: 0 none !important;
}

.ant-modal-root .ant-modal .ant-modal-body .ant-radio-group label {
    color: #01154b;
    font-weight: 400;
}

.ant-modal-root .ant-modal .ant-modal-body .ant-radio-group label.ant-radio-button-wrapper-checked {
    color: #115ae3;
    border-color: #115ae3;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
}

#root {
    height: 100%;
}

.buttons {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
}

.buttons-row {
    margin-top: 7px !important;
    margin-bottom: 0 !important;
}

.toolbar {
    display: flex;
    flex-direction: row;
    margin-bottom: 28px;
}

.toolbar > a,
.toolbar > button {
    margin-left: 300px;
}

.link {
    cursor: pointer;
}

.ql-container {
    font-family: 'IBMPlexSans' !important;
    font-size: 14px !important;
}

.ql-editor {
    min-height: 100px;
}

.ql-bubble .ql-editor {
    padding: 0;
    min-height: auto;
    margin-bottom: 28px;
}

.ql-bubble .ql-tooltip {
    display: none;
}
