.arrow {
    font-size: 18px;
    vertical-align: middle;
    margin-left: 12px;
    transform: rotate(-90deg);
}

.ant-collapse-item-active .arrow {
    transform: rotate(0deg);
}
            
           
