.buttons {
    text-align: right;

    .link {
        font-family: 'IBMPlexSans-SemiBold';
        font-size: 14px;
        color: #0555e6;
        text-decoration: underline;
        padding-left: 0;
        margin-top: 32px;
        padding-right: 0;
    }
}

.container {
    h4 {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
    }

    p {
        color: #000;
        font-size: 18px;
        font-family: IBMPlexSans;
    }

    .right {
        text-align: right;
    }

    .results {
        margin-top: 14px;
        margin-bottom: 16px;
    }

    .results svg circle:nth-child(2) {
        stroke: #00154b !important;
    }

    .legend {
        margin-top: 28px;
    }

    .legend p {
        font-family: 'IBMPlexSans';
        font-size: 12px;
        color: #8c8c8c;
        margin-bottom: 3px;
    }

    .legend p span {
        display: inline-block;
        width: 12px;
        height: 12px;
        vertical-align: middle;
        margin-right: 7px;
    }

    .legend p.passed span {
        background-color: #00154b;
    }

    .legend p.failed span {
        background-color: #eef0f2;
    }
}
