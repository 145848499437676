.question {
    padding-left: 0;
    padding-right: 0;
}

.question > div > div > span {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 25px;
    color: #01174a;
}

.buttons {
    width: 100%;
    margin-top: 42px;
    justify-content: center;
}

.buttons .page {
    margin-right: 42px;
}
