.subheader {
    display: flex;
    flex-direction: row;
}

.subheader > h1 {
    flex-grow: 1;
    font-weight: 600;
    margin-bottom: 28px;

    div:first-child {
        font-size: 21px;
    }
}

.subheader > a,
.subheader > button {
    margin-left: 14px;
    margin-right: 8px;
}
