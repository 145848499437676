.steps {
    margin-bottom: 42px;
}

.pagination {
    margin-top: 84px;
}

.files {
    padding-left: 0;
    padding-right: 0;
}

.files > div > div > span {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 25px;
    color: #01174a;
}

.buttons {
    margin-top: 42px;
    text-align: center;
}
