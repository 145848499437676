.steps {
    margin-bottom: 42px;
}

.steps .empty {
    color: transparent;
}

.result h3 {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 35px;
    color: #01174a;
}

.result h4 {
    color: #000;
    font-size: 15px;
}

.result .image svg {
    font-size: 329px;
}
