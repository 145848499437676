h1.title {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 28px;
}

.link {
    font-family: 'IBMPlexSans-SemiBold';
    font-size: 16px;
    color: #0555e6;
    text-decoration: underline;
    padding-left: 0;
    margin-top: 14px;
    margin-bottom: 42px;
}

.loader {
    margin-bottom: 56px;
}

.loader h3 {
    display: inline;
    margin-left: 14px;
}

.card {
    min-height: 495px;
}

.right {
    text-align: right;
}

.results {
    margin-top: 14px;
}

.results svg circle:nth-child(2) {
    stroke: #00154b !important;
}

.tag {
    margin-right: 0;
}

.legend {
    margin-top: 28px;
}

.legend p {
    font-family: 'IBMPlexSans';
    font-size: 12px;
    color: #8c8c8c;
    margin-bottom: 3px;
}

.legend p span {
    display: inline-block;
    width: 12px;
    height: 12px;
    vertical-align: middle;
    margin-right: 7px;
}

.legend p.passed span {
    background-color: #00154b;
}

.legend p.failed span {
    background-color: #eef0f2;
}

.profile {
    font-family: 'IBMPlexSans-SemiBold';
    color: #00154b;
}

.empty {
    text-align: left;
    margin-bottom: 42px;
}

.empty div:first-child {
    display: none;
}
