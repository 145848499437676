.templates {
    line-height: 40px;
    font-size: 16px;
}

.templates button {
    opacity: 0.6;
    margin-right: 16px;
}

.logo {
    height: 16px;
}
