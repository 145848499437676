.layout {
    flex-direction: column;
    display: flex;
    min-height: 100%;
    background: #eef0f2;
}

.layout header {
    color: #fff;
    background-color: #00154b;
    font-family: 'IBMPlexSans';
}

.layout .content {
    flex: 1;
    padding: 50px;
}

.layout .footer {
    padding: 7px 0;
    margin: 0 50px;
    line-height: 28px;
    border-top: 1px solid #d9d9d9;
    text-align: right;
    background: #eef0f2;
}

.layout .footer a {
    font-size: 12px;
    color: rgba(0, 21, 75, 0.3);
}
