.skill {
    margin-bottom: 16px;

    .skillHeader {
        margin-left: -12px;
        p {
            margin-bottom: 0;
            &:first-child {
                color: #00154b;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 4px;

                .arrow {
                    font-size: 18px;
                    vertical-align: middle;
                    margin-left: 12px;
                    transform: rotate(-90deg);
                }
            }
            &:nth-child(2) {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }

    .question {
        p:first-child {
            margin-bottom: 8px;
        }

        .answer:empty:before {
            content: '\200b';
        }

        .correctAnswer {
            color: #00154b;
            font-size: 13px;
            font-style: italic;
        }
    }
}
